<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title style="word-break: normal;">
        Cierres de Lote de la última caja
        <div v-if="ptovta_nombre != ''" class="body-1 mt-1 ml-sm-3 ml-0">
          {{ ptovta_nombre }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 pb-sm-4 pb-2">
        <v-data-table
          class="cebra"
          sort-by="terminal"
          :headers="headers"
          :items="cierres"
          :loading="load"
          :items-per-page="-1"
          hide-default-footer
          mobile-breakpoint
          dense
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.pendiente > 0"
              color="info"
              title="Sincronizar Cierre"
              class="mr-2"
              small
              @click="sincronizar(item)"
            >
              fas fa-sync
            </v-icon>
            <v-icon
              v-if="item.pendiente == 0"
              color="success"
              title="Enviar Cierre de Lote"
              class="mr-2"
              small
              @click="enviar_cierre(item)"
            >
              fas fa-arrow-circle-up
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="600"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron Cierres de Lote para la última caja abierta
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end pa-3">
          <v-btn
            color="info"
            class="ml-2 mt-sm-0 mt-2"
            :disabled="load || cierres.length == 0"
            @click="sincronizar_masivo()"
          >
            <v-icon small left>fas fa-sync</v-icon>
            Refrescar
          </v-btn>
          <BtnConfirmar
            nombre="Cierre Masivo"
            texto="Está enviando el cierre de lote de todos los LaPos del Local. ¿Confirma esta acción?"
            icono="fas fa-arrow-circle-up"
            clase="ml-2 mt-sm-0 mt-2"
            :disabled="load || cierres.length == 0"
            @action="enviar_cierre_masivo()"
          />
          <v-btn
            color="error"
            class="ml-2 mt-sm-0 mt-2"
            :disabled="load || cierres.length == 0"
            @click="dialog = false"
          >
            Cancelar
        </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      cierres: [],
      headers: [
        { text: 'Nº Terminal', align: 'center', value: 'terminal' },
        { text: 'Exitosos', align: 'center', value: 'exito' },
        { text: 'Pendientes', align: 'center', value: 'pendiente' },
        { text: 'Error', align: 'center', value: 'error' },
        { text: 'Acciones', align: 'center', value: 'actions', sortable: false, filterable: false }
      ]
    }
  },
  props: {
    value: Boolean,
    ptovta: Number,
    ptovta_nombre: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) this.obtener_cierres()
    }
  },
  methods: {
    async enviar_cierre (item) {
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('laposIntegrado/enviar_cierre_lote', item.terminal)
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.obtener_cierres()
      } else {
        this.$swal.fire({
          icon: 'error',
          title: resultado.message
        })
      }
    },
    async sincronizar (item) {
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('laposIntegrado/actualizar_cierre_lote', item.terminal)
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.obtener_cierres()
      } else {
        this.$swal.fire({
          icon: 'error',
          title: resultado.message
        })
      }
    },
    async enviar_cierre_masivo () {
      let error = 0
      this.$store.state.loading = true
      for (const cierre of this.cierres) {
        // solo mando los que no tienen pendientes
        if (cierre.pendiente == 0) {
          let resultado = await this.$store.dispatch('laposIntegrado/enviar_cierre_lote', cierre.terminal)
          if (resultado.exito == 0) error ++
        }
      }
      this.$store.state.loading = false

      if (error > 0) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Algunos Cierres no fueron enviados correctamente. Vuelva a repetir el procedimiento para reintentar'
        })
      }
      this.obtener_cierres()
    },
    async sincronizar_masivo () {
      let error = 0
      this.$store.state.loading = true
      for (const cierre of this.cierres) {
        if (cierre.pendiente > 0) {
          let resultado = await this.$store.dispatch('laposIntegrado/actualizar_cierre_lote', cierre.terminal)
          if (resultado.exito == 0) error ++
        }
      }
      this.$store.state.loading = false

      if (error > 0) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Algunos cierres no se sincronizaron correctamente. Vuelva a refrescar para reintentar'
        })
      }
      this.obtener_cierres()
    },
    async obtener_cierres () {
      this.cierres = []

      this.load = true
      let resultado = await this.$store.dispatch('laposIntegrado/get_cierre_lote', this.ptovta)
      this.load = false

      if (resultado.exito == 1) {
        this.cierres = resultado.terminales
      } else {
        this.$swal.fire({
          icon: 'error',
          title: resultado.message
        })
      }
    }
  }
}
</script>